import React from 'react';
import { Button } from 'react-bootstrap';
import './Button.css';

const DefaultButton = (props) => {
    return (
        <Button
            className='purple-button'
            onClick={props.onClick}
        >
            {props.text}
        </Button>
    );
};

export default DefaultButton;
