import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Resizable } from 're-resizable';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay-ts';
import { DataContext } from '../components/DataContext';
import { DefaultDateChart } from '../components/DefaultDateChart';
import ISOPointTable from '../components/ISOPointTable';
import ISOFeatureAllPointTable from '../components/ISOFeatureAllPointTable';
import ISOFeatureByPointTable from '../components/ISOFeatureByPointTable';
import Loading from '../components/Loading';
import './Point.css';

const Point = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [isDateDisabled, setIsDateDisabled] = useState(true);
    const [isPointDisabled, setIsPointDisabled] = useState(true);
    const [isFeaturesDisabled, setIsFeaturesDisabled] = useState(true);
    const [featuresRef, setFeaturesRef] = useState(null);
    const [dateOptions, setDateOptions] = useState([]);
    const [pointOptions, setPointOptions] = useState([]);
    const [featuresOption, setFeaturesOptions] = useState([]);
    const [hChartOptions, setHChartOptions] = useState(DefaultDateChart);
    const [zChartOptions, setZChartOptions] = useState(DefaultDateChart);
    const [pChartOptions, setPChartOptions] = useState(DefaultDateChart);
    const [featureChartOptions, setFeatureChartOptions] = useState(DefaultDateChart);
    const [ISOPointRows, setISOPointRows] = useState([]);
    const [ISOFeatureAllPointColumns, setISOFeatureAllPointColumns] = useState([]);
    const [ISOFeatureAllPointRows, setISOFeatureAllPointRows] = useState([]);
    const [ISOFeatureByPointColumns, setISOFeatureByPointColumns] = useState([]);
    const [ISOFeatureByPointRows, setISOFeatureByPointRows] = useState([]);
    const [isActive, setActive] = useState(true);
    const data = useContext(DataContext);

    useEffect(() => {
        const getToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: 'scg-chem-test',
                    scope: 'read:test',
                });
                data.setToken(accessToken);
            } catch (e) {
                console.log(e.message);
            }
        };
        getToken();
    }, []);

    useEffect(() => {
        const getOverallHChart = async (area_id, equipment_id, type, setOptions, payload) => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/feature-overall/${area_id}/${equipment_id}/${type}`, payload, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setOptions({
                    xAxis: {
                        categories: response.data.dates
                    },
                    series: response.data.points,
                    yAxis: {
                        title: {
                            text: response.data.unit,
                        },
                        plotLines: [
                            {
                                color: '#fb9b05',
                                width: 3,
                                value: response.data.warning_threshold,
                                zIndex: 5,
                                dashStyle: 'dash',
                                label: {
                                    text: 'Warning',
                                    align: 'right',
                                    y: 20,
                                    style: {
                                        color: '#fb9b05',
                                        fontWeight: 'bold'
                                    }
                                }
                            }, {
                                color: '#ec3424',
                                width: 3,
                                value: response.data.alert_threshold,
                                zIndex: 5,
                                dashStyle: 'dash',
                                label: {
                                    text: 'Danger',
                                    align: 'right',
                                    y: 20,
                                    style: {
                                        color: '#ec3424',
                                        fontWeight: 'bold'
                                    }
                                }
                            }, 
                        ],
                        max: Math.max(response.data.alert_threshold, response.data.max_overall)
                    }
                });
            } catch (e) {
                console.log('Error from feature-overall');
            }
            setActive(false);
        };

        const getOverallPZChart = async (area_id, equipment_id, type, setOptions, payload) => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/feature-overall/${area_id}/${equipment_id}/${type}`, payload, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setOptions({
                    xAxis: {
                        categories: response.data.dates
                    },
                    series: response.data.points,
                    yAxis: {
                        title: {
                            text: response.data.unit,
                        },
                        plotLines: [
                            {
                                color: '#63bc44',
                                width: 3,
                                value: response.data.mean,
                                zIndex: 5,
                                dashStyle: 'dash',
                                label: {
                                    text: 'Average',
                                    align: 'right',
                                    y: 20,
                                    style: {
                                        color: '#63bc44',
                                        fontWeight: 'bold',
                                    }
                                }
                            }, {
                                color: '#ec3424',
                                width: 3,
                                value: response.data.mean_3std,
                                zIndex: 5,
                                dashStyle: 'dash',
                                label: {
                                    text: 'Danger (Anomaly)',
                                    align: 'right',
                                    y: 20,
                                    style: {
                                        color: '#ec3424',
                                        fontWeight: 'bold'
                                    }
                                }
                            }, 
                        ],
                        max: Math.max(response.data.mean_3std, response.data.max_overall)
                    }
                });
            } catch (e) {
                console.log('Error from feature-overall');
            }
            setActive(false);
        };

        const getPoints = async (area_id, equipment_id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/spectrum/${area_id}/${equipment_id}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setPointOptions(response.data);
            } catch (e) {
                console.log('Error from spectrum');
            }
        };

        const getDates = async (area_id, equipment_id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/date/${area_id}/${equipment_id}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setDateOptions(response.data);
            } catch (e) {
                console.log('Error from date');
            }
        };

        const getISOFeaturesAllPoints = async (area_id, equipment_id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/recently-measurements/${area_id}/${equipment_id}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setISOFeatureAllPointColumns([
                    {
                        "id": "point",
                        "label": "Point",
                        "minWidth": 80
                    },
                    {
                        "id": "feature",
                        "label": "Feature",
                        "minWidth": 150
                    },
                    {
                        "id": "formula",
                        "label": "Formula",
                        "minWidth": 200
                    },
                    {
                        "id": "status",
                        "label": "Status",
                        "minWidth": 100
                    },
                    {
                        "id": "unit",
                        "label": "Unit",
                        "minWidth": 60
                    },
                    {
                        "id": "month_1",
                        "label": response.data.month_1,
                        "minWidth": 120,
                        "align": "right"
                    },
                    {
                        "id": "month_2",
                        "label": response.data.month_2,
                        "minWidth": 120,
                        "align": "right"
                    },
                    {
                        "id": "percent_change",
                        "label": "% change",
                        "minWidth": 100,
                        "align": "right"
                    },
                    {
                        "id": "danger",
                        "label": "Danger (Anomaly)",
                        "minWidth": 80,
                        "align": "right"
                    }
                ]);
                setISOFeatureAllPointRows(response.data.rows);
            } catch (e) {
                console.log('Error from recently-measurements');
            }
        };

        if (data.pointRef) {
            clearPointValue();
        }
        if (data.dateRef) {
            clearDateValue();
        }
        if (data.equipment !== '') {
            const payload = {
                'start_date': data.startDate.getDate() + "/" + (data.startDate.getMonth() + 1) + "/" + data.startDate.getFullYear(),
                'end_date': data.stopDate.getDate() + "/" + (data.stopDate.getMonth() + 1) + "/" + data.stopDate.getFullYear(),
            }
            getOverallHChart(data.area, data.equipment, 'H', setHChartOptions, payload);
            getOverallPZChart(data.area, data.equipment, 'Z', setZChartOptions, payload);
            getOverallPZChart(data.area, data.equipment, 'P', setPChartOptions, payload);
            getDates(data.area, data.equipment);
            getISOFeaturesAllPoints(data.area, data.equipment);
            getPoints(data.area, data.equipment);
            setIsDateDisabled(false);
            setIsPointDisabled(false);
        } else {
            setHChartOptions(DefaultDateChart);
            setPChartOptions(DefaultDateChart);
            setZChartOptions(DefaultDateChart);
            setISOFeatureAllPointColumns([]);
            setISOFeatureAllPointRows([]);
            setIsDateDisabled(true);
            setIsPointDisabled(true);
        }
    }, [data.equipment]);

    useEffect(() => {
        const getOverallHChart = async (area_id, equipment_id, type, setOptions, payload) => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/feature-overall/${area_id}/${equipment_id}/${type}`, payload, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setOptions({
                    xAxis: {
                        categories: response.data.dates
                    },
                    series: response.data.points,
                    yAxis: {
                        title: {
                            text: response.data.unit,
                        },
                        plotLines: [
                            {
                                color: '#fb9b05',
                                width: 3,
                                value: response.data.warning_threshold,
                                zIndex: 5,
                                dashStyle: 'dash',
                                label: {
                                    text: 'Warning',
                                    align: 'right',
                                    y: 20,
                                    style: {
                                        color: '#fb9b05',
                                        fontWeight: 'bold'
                                    }
                                }
                            }, {
                                color: '#ec3424',
                                width: 3,
                                value: response.data.alert_threshold,
                                zIndex: 5,
                                dashStyle: 'dash',
                                label: {
                                    text: 'Danger',
                                    align: 'right',
                                    y: 20,
                                    style: {
                                        color: '#ec3424',
                                        fontWeight: 'bold'
                                    }
                                }
                            }, 
                        ],
                        max: Math.max(response.data.alert_threshold, response.data.max_overall)
                    }
                });
            } catch (e) {
                console.log('Error from feature-overall');
            }
            setActive(false);
        };

        const getOverallPZChart = async (area_id, equipment_id, type, setOptions, payload) => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/feature-overall/${area_id}/${equipment_id}/${type}`, payload, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setOptions({
                    xAxis: {
                        categories: response.data.dates
                    },
                    series: response.data.points,
                    yAxis: {
                        title: {
                            text: response.data.unit,
                        },
                        plotLines: [
                            {
                                color: '#63bc44',
                                width: 3,
                                value: response.data.mean,
                                zIndex: 5,
                                dashStyle: 'dash',
                                label: {
                                    text: 'Average',
                                    align: 'right',
                                    y: 20,
                                    style: {
                                        color: '#63bc44',
                                        fontWeight: 'bold',
                                    }
                                }
                            }, {
                                color: '#ec3424',
                                width: 3,
                                value: response.data.mean_3std,
                                zIndex: 5,
                                dashStyle: 'dash',
                                label: {
                                    text: 'Danger (Anomaly)',
                                    align: 'right',
                                    y: 20,
                                    style: {
                                        color: '#ec3424',
                                        fontWeight: 'bold'
                                    }
                                }
                            }, 
                        ],
                        max: Math.max(response.data.mean_3std, response.data.max_overall)
                    }
                });
            } catch (e) {
                console.log('Error from feature-overall');
            }
            setActive(false);
        };

        if (data.equipment !== '') {
            const payload = {
                'start_date': data.startDate.getDate() + "/" + (data.startDate.getMonth() + 1) + "/" + data.startDate.getFullYear(),
                'end_date': data.stopDate.getDate() + "/" + (data.stopDate.getMonth() + 1) + "/" + data.stopDate.getFullYear(),
            }
            getOverallHChart(data.area, data.equipment, 'H', setHChartOptions, payload);
            getOverallPZChart(data.area, data.equipment, 'Z', setZChartOptions, payload);
            getOverallPZChart(data.area, data.equipment, 'P', setPChartOptions, payload);
        }
    }, [data.startDate, data.stopDate]);

    useEffect(() => {
        const getISOPoints = async (area_id, equipment_id, date) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/iso-point/${area_id}/${equipment_id}/${date}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setISOPointRows(response.data);
            } catch (e) {
                console.log('Error from iso-point');
            }
        };

        if (data.date !== '') {
            getISOPoints(data.area, data.equipment, data.date);
        } else {
            setISOPointRows([]);
        }
    }, [data.date]);

    useEffect(() => {
        const getFeatures = async (area_id, equipment_id, point_id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/feature-names/${area_id}/${equipment_id}/${point_id}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setFeaturesOptions(response.data);
            } catch (e) {
                console.log('Error from feature-names');
            }
        };

        const getISOFeaturesByPoints = async (area_id, equipment_id, point_id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/recently-measurements-point/${area_id}/${equipment_id}/${point_id}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setISOFeatureByPointColumns([
                    {
                        "id": "feature",
                        "label": "Feature",
                        "minWidth": 200
                    },
                    {
                        "id": "formula",
                        "label": "Formula",
                        "minWidth": 250
                    },
                    {
                        "id": "status",
                        "label": "Status",
                        "minWidth": 100
                    },
                    {
                        "id": "unit",
                        "label": "Unit",
                        "minWidth": 60
                    },
                    {
                        "id": "month_1",
                        "label": response.data.month_1,
                        "minWidth": 120,
                        "align": "right"
                    },
                    {
                        "id": "month_2",
                        "label": response.data.month_2,
                        "minWidth": 120,
                        "align": "right"
                    },
                    {
                        "id": "percent_change",
                        "label": "% change",
                        "minWidth": 100,
                        "align": "right"
                    },
                    {
                        "id": "danger",
                        "label": "Danger (Anomaly)",
                        "minWidth": 80,
                        "align": "right"
                    }
                ]);
                setISOFeatureByPointRows(response.data.rows);
            } catch (e) {
                console.log('Error from recently-measurements-point');
            }
        };
        
        if (featuresRef) {
            clearFeaturesValue();
        }
        if (data.point !== '') {
            getFeatures(data.area, data.equipment, data.point);
            getISOFeaturesByPoints(data.area, data.equipment, data.point);
            setIsFeaturesDisabled(false);
        } else {
            setIsFeaturesDisabled(true);
        }
    }, [data.point]);

    useEffect(() => {
        const getFeaturesValue = () => {
            featuresRef.setState({ value: featuresOption });
            const words = featuresOption.map(function(feature){
                return feature.value;
            });
            getFeaturesChart(data.area, data.equipment, data.point, words);
        };

        if (featuresOption.length !== 0) {
            getFeaturesValue();
        }
    }, [featuresOption, data.startDate, data.stopDate])

    const getFeaturesChart = async (area_id, equipment_id, point_id, words) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/feature/${area_id}/${equipment_id}/${point_id}`, {
                'feature_names': words,
                'start_date': data.startDate.getDate() + "/" + (data.startDate.getMonth() + 1) + "/" + data.startDate.getFullYear(),
                'end_date': data.stopDate.getDate() + "/" + (data.stopDate.getMonth() + 1) + "/" + data.stopDate.getFullYear()
            }, {
                headers: {
                    Authorization: `Bearer ${data.token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            if (words.length === 1) {
                setFeatureChartOptions({
                    xAxis: {
                        categories: response.data.dates
                    },
                    series: response.data.features,
                    yAxis: {
                        plotLines: [
                            {
                                color: '#63bc44',
                                width: 3,
                                value: response.data.mean,
                                zIndex: 5,
                                dashStyle: 'dash',
                                label: {
                                    text: 'Average',
                                    align: 'right',
                                    y: 20,
                                    style: {
                                        color: '#63bc44',
                                        fontWeight: 'bold',
                                    }
                                }
                            }, {
                                color: '#ec3424',
                                width: 3,
                                value: response.data.mean_3std,
                                zIndex: 5,
                                dashStyle: 'dash',
                                label: {
                                    text: 'Danger (Anomaly)',
                                    align: 'right',
                                    y: 20,
                                    style: {
                                        color: '#ec3424',
                                        fontWeight: 'bold'
                                    }
                                }
                            }, 
                        ]
                    }
                });
            } else {
                setFeatureChartOptions({
                    xAxis: {
                        categories: response.data.dates
                    },
                    series: response.data.features,
                    yAxis: {
                        plotLines: []
                    },
                });
            }
        } catch (e) {
            console.log('Error from feature');
        }
    };

    const clearDateValue = () => {
        data.dateRef.select.clearValue();
        setISOPointRows([]);
    };

    const clearPointValue = () => {
        data.pointRef.select.clearValue();
        setISOFeatureByPointColumns([]);
        setISOFeatureByPointRows([]);
    };

    const clearFeaturesValue = () => {
        featuresRef.select.clearValue();
        setFeatureChartOptions(DefaultDateChart);
    };

    const onSelectDate = (selectedItem) => {
        if (selectedItem !== null) {
            data.setDate(selectedItem.value);
        } else {
            data.setDate('');
        }
    };

    const onSelectPoint = (selectedItem) => {
        if (selectedItem !== null) {
            data.setPoint(selectedItem.value);
        } else {
            data.setPoint('');
        }
    };
    
    const onSelectFeatures = (selectedItem) => {
        if (selectedItem.length !== 0) {
            const words = selectedItem.map(function(feature){
                return feature.value;
            });
            getFeaturesChart(data.area, data.equipment, data.point, words);
        }
    };

    return (
        isAuthenticated && (
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading point detail...'>
                <div className='container'>
                    <br></br>
                    <strong className='header' id='machine_health_condition'>Overall Selected Machine Health Condition Graph</strong>
                    <strong className='sub-header'>Overall Velocity Graph</strong>
                    <p className='description'>This graph indicates trend for overall velocity in each measurement point.</p>
                    <br></br>
                    <div className='point-chart-container'>
                        <Resizable className="box" maxWidth={1100} minWidth={1100} lockAspectRatio>
                            <HighchartsReact highcharts={Highcharts} options={hChartOptions} />
                        </Resizable>
                    </div>
                    <br></br>
                    <strong className='sub-header'>Overall Acceleration Graph</strong>
                    <p className='description'>This graph indicates trend for overall acceleration in each measurement point.</p>
                    <br></br>
                    <div className='point-chart-container'>
                        <Resizable className="box" maxWidth={1100} minWidth={1100} lockAspectRatio>
                            <HighchartsReact highcharts={Highcharts} options={zChartOptions} />
                        </Resizable>
                    </div>
                    <br></br>
                    <strong className='sub-header'>Overall Enveloping Graph</strong>
                    <p className='description'>This graph indicates trend for overall enveloping in each measurement point.</p>
                    <br></br>
                    <div className='point-chart-container'>
                        <Resizable className="box" maxWidth={1100} minWidth={1100} lockAspectRatio>
                            <HighchartsReact highcharts={Highcharts} options={pChartOptions} />
                        </Resizable>
                    </div>
                    <br></br>
                    <strong className='header' id='machine_status'>Summary of Machine Status for Overall Data</strong>
                    <p className='description'>Table for summary of machine condition status from overall vibration data in each measurement point on selected date</p>
                    <br></br>
                    <div className='point-dropdown-container'>
                        <div className='point-select-dropdown'>
                            <Select
                                ref={ref => {data.setDateRef(ref);}}
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={isDateDisabled}
                                options={dateOptions}
                                placeholder="Select Date"
                                isMulti={false}
                                onChange={onSelectDate}
                                styles={{width: "300px"}}
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className='point-table-container'>
                        <Resizable className="box" maxWidth={800} minWidth={600} lockAspectRatio>
                            <ISOPointTable rows={ISOPointRows} />
                        </Resizable>
                    </div>
                    <br></br>
                    <strong className='header' id='band_alarm'>Overall Band Alarm</strong>
                    <p className='description'>This table indicates machine status from band alarm analysis compared between present and last measure in each failure mode at each measurement position.
                        <br></br>1. "Feature" column indicates spectrum in X order form to compare with machine speed.
                        <br></br>2. "% change" column indicates the comparison between the change of present and last measurement value. 
                        <br></br>If the value is higher, it means some parts of the machine have significant changes in reality.
                        <br></br>3. "Danger (Anomaly)" column indicates severity level with matching failure.</p>
                    <br></br>
                    <div className='point-table-container'>
                        <Resizable className="box" maxWidth={1100} minWidth={1100} lockAspectRatio>
                            <ISOFeatureAllPointTable columns={ISOFeatureAllPointColumns} rows={ISOFeatureAllPointRows} />
                        </Resizable>
                    </div>
                    <br></br>
                    <strong className='header' id='band_alarm_trend'>Band Alarm Analysis Trend</strong>
                    <p className='description'>This graph indicates the trend in each band alarm analysis type and summary of all data in the table below with comparison %change to last measurement data.</p>
                    <br></br>
                    <div className='feature-dropdown-container'>
                        <div className='point-select-dropdown'>
                            <Select
                                ref={ref => {data.setPointRef(ref);}}
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={isPointDisabled}
                                options={pointOptions}
                                placeholder="Select Point"
                                isMulti={false}
                                onChange={onSelectPoint}
                            />
                        </div>
                        <div className='feature-select-multi-dropdown'>
                            <Select
                                ref={ref => {setFeaturesRef(ref);}}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={isFeaturesDisabled}
                                options={featuresOption}
                                placeholder="Select Features"
                                isMulti={true}
                                onChange={onSelectFeatures}
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className='point-chart-container'>
                        <Resizable className="box" maxWidth={1100} minWidth={1100} lockAspectRatio>
                            <HighchartsReact highcharts={Highcharts} options={featureChartOptions} />
                        </Resizable>
                    </div>
                    <br></br>
                    <div className='point-table-container'>
                        <Resizable className="box" maxWidth={1100} minWidth={1100} lockAspectRatio>
                            <ISOFeatureByPointTable columns={ISOFeatureByPointColumns} rows={ISOFeatureByPointRows} />
                        </Resizable>
                    </div>
                    <br></br>
                </div>
            </LoadingOverlay>
        )
    );
};

export default withAuthenticationRequired(Point, {
    onRedirecting: () => <Loading page='point' />,
});
