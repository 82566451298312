import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutButton from '../components/LogoutButton';
import DefaultButton from '../components/DefaultButton';
import SignInButton from './SignInButton';
import { IconContext } from 'react-icons';
import logo from '../assets/logo.png';
import './Sidebar.css';

function Sidebar() {
    const { isAuthenticated } = useAuth0();

    return (
        <>
            {isAuthenticated ? 
                <IconContext.Provider value={{color: '#fff'}}>
                    <div className="sidebar">
                        <div className="sidebar-container">
                            <a onClick={() => window.location.href="/home"}>
                                <img style={{ height: '60px', }} src={logo} alt='logo' />
                            </a>
                            <div className="button-container">
                                <a onClick={() => window.location.href="/report"}>
                                    <DefaultButton text='DASHBOARD' />
                                </a>
                                <LogoutButton />
                            </div>
                        </div>
                    </div>
                </IconContext.Provider> 
            :
                <IconContext.Provider value={{color: '#fff'}}>
                    <div className="sidebar">
                        <div className="sidebar-container">
                            <img style={{ height: '60px', }} src={logo} alt='logo' />
                            <div className="button-container">
                                <SignInButton />
                                <DefaultButton text='CONTACT US' onClick={() => {alert('For more information about the service, please contact repconex@scg.com or call at +66 3-891-1959, +66 95-492-4174');}}/>
                            </div>
                        </div>
                    </div>
                </IconContext.Provider>
            }
        </>
    )
}

export default Sidebar
