import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './Button.css';

const SignInButton = (props) => {
    const history = useHistory();

    return (
        <Button
            className='purple-button'
            onClick={() => history.push('/home')}
        >
            SIGN IN
        </Button>
    );
};

export default SignInButton;
