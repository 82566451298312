import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'react-bootstrap'
import axios from 'axios';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Resizable } from 're-resizable';
import LoadingOverlay from 'react-loading-overlay-ts';
import { DataContext } from '../components/DataContext';
import EquipmentPointDetailTable from '../components/EquipmentPointDetailTable';
import EquipmentPointAnomalyTable from '../components/EquipmentPointAnomalyTable';
import ImageTooltip from '../components/ImageTooltip';
import unnamed from '../assets/img-not-found.png';
import Loading from '../components/Loading';
import './Equipment.css';

function Equipment() {
    const { isAuthenticated } = useAuth0();
    const [equipmentDetail, setEquipmentDetail] = useState([]);
    const [pointDetail, setPointDetail] = useState([]);
    const [pointAnomaly, setPointAnomaly] = useState([]);
    const [imageTooltips, setImageTooltips] = useState([]);
    const [isActive, setActive] = useState(true);
    const [showPointDetail, setShowPointDetail] = useState(false);
    const data = useContext(DataContext);

    useEffect(() => {
        const getEquipmentDetail = async (area_id, equipment_id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/equipment-detail/${area_id}/${equipment_id}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setEquipmentDetail(response.data);
            } catch (e) {
                console.log('Error from equipment-detail');
            }
        };

        const getEquipmentTooltip = async (area_id, equipment_id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/tooltips/${area_id}/${equipment_id}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setImageTooltips(response.data.map((tooltip) =>
                    <ImageTooltip short={tooltip.short} title={tooltip.title} left={tooltip.left} top={tooltip.top} key={tooltip.short} />
                ));
            } catch (e) {
                console.log('Error from tooltips');
            }
        };

        const getPointDetail = async (area_id, equipment_id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/point-detail/${area_id}/${equipment_id}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setPointDetail(response.data);
            } catch (e) {
                console.log('Error from point-detail');
            }
        };

        const getPointAnomaly = async (area_id, equipment_id) => {
            setActive(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/anomaly-point/${area_id}/${equipment_id}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setPointAnomaly(response.data);
                setActive(false);
            } catch (e) {
                console.log('Error from anomaly-point');
            }
        };

        if (data.equipment !== ''){
            getEquipmentDetail(data.area, data.equipment);
            getEquipmentTooltip(data.area, data.equipment);
            getPointDetail(data.area, data.equipment);
            getPointAnomaly(data.area, data.equipment);
        } else {
            setImageTooltips([]);
            setEquipmentDetail([]);
            setPointDetail([]);
            setPointAnomaly([]);
        }
    }, [data.equipment]);

    const onCollapsePointDetail = () => {
        setShowPointDetail(showPointDetail => !showPointDetail);
    };
    
    return (
        isAuthenticated && (
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading equipment detail...'>
                <div className='container'>
                    <br></br>
                    <strong className='header' id='equipment'>Equipment</strong>
                    <p className='description'>Model and specification of selected machine</p>
                    <br></br>
                    <br></br>
                    <div className='equipment-detail-container'>
                        <div className='picture-container'>
                            {imageTooltips}
                            {equipmentDetail[3] === '-' ? <img className='picture' src={unnamed} alt="" />
                                : <img className='picture' src={equipmentDetail[3]} alt="" />}
                        </div>
                        <div className='equipment-title'>
                            <div className='equipment-title-text'>Machine Description:</div>
                            <div className='equipment-title-text'>Driver Unit:</div>
                            <div className='equipment-title-text'>Driven Unit:</div>
                            <div className='equipment-title-text'>Power Transmission:</div>
                            <div className='equipment-title-text'>Main Type:</div>
                            <div className='equipment-title-text'>Sub Type:</div>
                            <div className='equipment-title-text'>Power:</div>
                            <div className='equipment-title-text'>Speed Variation:</div>
                        </div>
                        <div className='equipment-value'>
                            <div className='equipment-value-text'>{equipmentDetail[2]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[7]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[8]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[9]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[10]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[11]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[14]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[15]}</div>
                        </div>
                    </div>
                    <br></br>
                    <strong className='header' id='machine_fault_analysis'>Machine Fault Analysis</strong>
                    <p className='description'>Result of spectrum analysis in each measurement point including possible cause and severity level</p>
                    <br></br>
                    <div className='equipment-table-container'>
                        <Resizable className="box" maxWidth={1100} minWidth={1100} lockAspectRatio>
                            <EquipmentPointAnomalyTable rows={pointAnomaly} />
                        </Resizable>
                    </div>
                    <br></br>
                    <br></br>
                    {showPointDetail ?
                        <Button className="equipment-collapsible-button" onClick={onCollapsePointDetail}>
                            Hide Risk Calculation Formula
                        </Button> :
                        <Button className="equipment-collapsible-button" onClick={onCollapsePointDetail}>
                            Show Risk Calculation Formula
                        </Button>
                    }
                    {showPointDetail ? 
                        <>
                            <br></br>
                            <strong className='header'>Table of Risk Calculation Formula</strong>
                            <p className='description'>Reference of machine fault analysis calculation formula in each measurement point</p>
                            <br></br>
                            <div className='equipment-table-container'>
                                <Resizable className="box" maxWidth={1100} minWidth={1100} lockAspectRatio>
                                    <EquipmentPointDetailTable rows={pointDetail} />
                                </Resizable>
                            </div>
                        </>
                    : null}
                    <br></br>
                </div>
            </LoadingOverlay>
        )
    )
};

export default withAuthenticationRequired(Equipment, {
    onRedirecting: () => <Loading page='equipment' />,
});
