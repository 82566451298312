import React from 'react';
import { Button } from 'react-bootstrap';
import './Button.css';

const DetailButton = (props) => {
    const onClick = (id) => {
        // const url = `http://myapp.example:3000/detail/${id}`;
        const url = `https://qa.afd.chem.scg.jumpai.tech/detail/${id}`;
        window.open(url, '_blank');
    }

    return (
        <Button
            onClick={() => onClick(props.id)}
            id={props.id}
            className='blue-button'
        >
            Detail
        </Button>
    );
};

export default DetailButton;