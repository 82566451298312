export const DefaultSpectrumChart = {
    chart: {
        zoomType: 'x',
    },
    legend:{
        enabled:true,
    },
    title: {
        text: '',
    },
    tooltip: {
        valueDecimals: 4,
        shared: true,
    },
    credits: {
        enabled: false,
    },
    xAxis: {
        allowDecimals: false,
        title: {
            text: 'Frequency',
        },
        categories: [],
        minTickInterval: 80,
        labels: {
            formatter: function() {
                return this.value.toFixed(0);
            },
        }
    },
    yAxis: {
        title: {
            text: '',
        }
    },
    plotOptions: {
        series: {
            turboThreshold: 20000
        }
    },
    series: [
        {
            name: '',
            data: []
        }
    ],
}