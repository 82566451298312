import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Resizable } from 're-resizable';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more'
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay-ts';
import { DataContext } from '../components/DataContext';
import { DefaultBubbleChart, DefaultBubbleSize } from '../components/DefaultBubbleChart';
import Loading from '../components/Loading';
import './Anomaly.css';
HC_more(Highcharts)

const Anomaly = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [isPointDisabled, setIsPointDisabled] = useState(true);
    const [isProblemDisabled, setIsProblemDisabled] = useState(true);
    const [pointOptions, setPointOptions] = useState([]);
    const [problemOptions, setProblemOptions] = useState([]);
    const [pointChartOptions, setPointChartOptions] = useState(DefaultBubbleChart);
    const [isActive, setActive] = useState(true);
    const data = useContext(DataContext);

    useEffect(() => {
        const getToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: 'scg-chem-test',
                    scope: 'read:test',
                });
                data.setToken(accessToken);
            } catch (e) {
                console.log(e.message);
            }
        };
        getToken();
    }, []);

    useEffect(() => {
        const getProblems = async (area_id, equipment_id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/all-problem/${area_id}/${equipment_id}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setProblemOptions(response.data);
            } catch (e) {
                console.log('Error from all-problem');
            }
        };

        if (data.problemRef) {
            clearProblemValue();
        }
        if (data.equipment !== '') {
            getProblems(data.area, data.equipment);
            setIsProblemDisabled(false);
        } else {
            setIsProblemDisabled(true);
        }
    }, [data.equipment]);

    useEffect(() => {
        const getPoints = async (area_id, equipment_id, problem) => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/problem/${area_id}/${equipment_id}`, {
                    'problem': problem,
                }, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setPointOptions(response.data);
            } catch (e) {
                console.log('Error from problem');
            }
        };

        if (data.anomalyPointsRef) {
            clearPointsValue();
        }
        if (data.problem !== '') {
            getPoints(data.area, data.equipment, data.problem);
            setIsPointDisabled(false);
        } else {
            setIsPointDisabled(true);
        }
    }, [data.problem]);

    useEffect(() => {
        const getPointProblemChart = async (area_id, equipment_id, point_id, problem) => {
            setActive(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/problem-severity-point/${area_id}/${equipment_id}/${point_id}`, {
                    'start_date': data.startDate.getDate() + "/" + (data.startDate.getMonth() + 1) + "/" + data.startDate.getFullYear(),
                    'end_date': data.stopDate.getDate() + "/" + (data.stopDate.getMonth() + 1) + "/" + data.stopDate.getFullYear(),
                    'problem': problem,
                }, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setPointChartOptions({
                    xAxis: {
                        categories: response.data.date_list,
                        min: 0,
                        max: response.data.date_list.length - 1,
                    },
                    yAxis: {
                        title: {
                            text: 'Amplitude'
                        },
                        categories: [],
                        min: 0,
                        max: null,
                    },
                    tooltip: {
                        pointFormat: 'Amplitude: {point.y:.3f}<br/>Severity: {point.z}'
                    },
                    series: [
                        {
                            name: problem,
                            data: response.data.severity.concat(DefaultBubbleSize),
                        }
                    ]
                });
            } catch (e) {
                console.log('Error from problem-severity-point');
            }
            setActive(false);
        };

        const getAllProblemChart = async (area_id, equipment_id, point_list, problem) => {
            setActive(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/problem-severity/${area_id}/${equipment_id}`, {
                    'start_date': data.startDate.getDate() + "/" + (data.startDate.getMonth() + 1) + "/" + data.startDate.getFullYear(),
                    'end_date': data.stopDate.getDate() + "/" + (data.stopDate.getMonth() + 1) + "/" + data.stopDate.getFullYear(),
                    'point_list': point_list,
                    'problem': problem,
                }, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setPointChartOptions({
                    xAxis: {
                        categories: response.data.date_list,
                        min: 0,
                        max: response.data.date_list.length - 1,
                    },
                    yAxis: {
                        title: {
                            text: 'Point'
                        },
                        categories: response.data.point_list,
                        min: 0,
                        max: response.data.point_list.length - 1,
                    },
                    tooltip: {
                        pointFormat: 'Severity: {point.z}'
                    },
                    series: [
                        {
                            name: problem,
                            data: response.data.severity.concat(DefaultBubbleSize),
                        }
                    ]
                });
            } catch (e) {
                console.log('Error from problem-severity');
            }
            setActive(false);
        };

        if (data.anomalyPoints.length !== 0) {
            if (data.anomalyPoints.length === 1) {
                getPointProblemChart(data.area, data.equipment, data.anomalyPoints[0], data.problem);
            } else {
                getAllProblemChart(data.area, data.equipment, data.anomalyPoints, data.problem);
            }
        } else {
            setPointChartOptions(DefaultBubbleChart);
        }
    }, [data.anomalyPoints, data.startDate, data.stopDate]);

    const clearProblemValue = () => {
        data.problemRef.select.clearValue();
        setPointChartOptions(DefaultBubbleChart);
    };

    const clearPointsValue = () => {
        data.anomalyPointsRef.select.clearValue();
        setPointChartOptions(DefaultBubbleChart);
    };

    const onSelectPoints = (selectedItem) => {
        if (selectedItem.length !== 0) {
            const points = selectedItem.map(function(point){
                return point.value;
            });
            data.setAnomalyPoints(points);
        } else {
            data.setAnomalyPoints([]);
        }
    };

    const onSelectProblem = (selectedItem) => {
        if (selectedItem !== null) {
            data.setProblem(selectedItem.value);
        } else {
            data.setProblem('');
        }
    };

    return (
        isAuthenticated && (
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading anomaly detail...'>
                <div className='container'>
                    <br></br>
                    <strong className='header' id='anomaly'>Anomaly Fault Detection</strong>
                    <p className='description'>The anomaly fault detection is a chart that shows the severity of matching with each failure mode. To interpret this chart, the bigger circle means high severity in that failure mode. The X-axis is the measurement date and the Y-axis is the measurement point.
                        <br></br>If only one point is selected, the X-axis is measurement date and Y-axis is the amplitude of that signal.</p>
                    <br></br>
                    <div className='anomaly-dropdown-container'>
                        <div className='anomaly-problem-select-dropdown'>
                            <Select
                                ref={ref => {data.setProblemRef(ref);}}
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={isProblemDisabled}
                                options={problemOptions}
                                placeholder="Select Problem"
                                isMulti={false}
                                onChange={onSelectProblem}
                            />
                        </div>
                        <div className='anomaly-points-select-dropdown'>
                            <Select
                                ref={ref => {data.setAnomalyPointsRef(ref);}}
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={isPointDisabled}
                                options={pointOptions}
                                placeholder="Select Points"
                                isMulti={true}
                                onChange={onSelectPoints}
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className="anomaly-chart-container">
                        <Resizable className="box" maxWidth={1100} minWidth={1100}lockAspectRatio>
                            <HighchartsReact highcharts={Highcharts} options={pointChartOptions} />
                        </Resizable>
                    </div>
                </div>
            </LoadingOverlay>
        )
    );
};

export default withAuthenticationRequired(Anomaly, {
    onRedirecting: () => <Loading page='anomaly' />,
});
