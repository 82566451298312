import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import DetailButton from '../components/DetailButton';

const columns = [
    { id: 'area_id', label: 'Area', minWidth: 240 },
    { id: 'equipment_id', label: 'Equipment', minWidth: 100 },
    { id: 'point_id', label: 'Point', minWidth: 60 },
    { id: 'date', label: 'Date', minWidth: 120, align: 'right' },
    { id: 'problem_type', label: 'Problem Type', minWidth: 120 },
    { id: 'problem_detail', label: 'Problem Detail', minWidth: 300 },
    { id: 'detail', label: 'Detail', minWidth: 120, align: 'center' },
];

const useStyles = makeStyles({
    tableRow: {
      height: 80,
    },
    tableCell: {
      padding: "0px 16px"
    },
    container: {
      maxHeight: 400,
    },
});

const EngineerRecommendationTable = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              {columns.map((column) => (
                <TableCell className={classes.tableCell}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id} className={classes.tableRow}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    if (column.id === 'detail') {
                      return (
                        <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                          <DetailButton id={row.id} />
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default EngineerRecommendationTable;
