import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import StopIcon from '@material-ui/icons/Stop';
import TreeItem from '@material-ui/lab/TreeItem';
import { DataContext } from './DataContext';

const useStyles = makeStyles({
    root: {
        flex: 1,
        maxWidth: 400,
    },
    label: {
        paddingTop: 2.5,
        paddingBottom: 2.5,
    },
});

export default function CurrentAreaSelection() {
    const classes = useStyles();
    const data = useContext(DataContext);

    return (
        <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultEndIcon={<StopIcon fontSize='small' />}
        >
            <TreeItem 
                nodeId="1" 
                label={`Company : ${data.summaryCompany}`} 
                className={classes.label}
                onClick={() => {
                    data.setSelectState(3);
                }} />
            <TreeItem 
                nodeId="2" 
                label={`Database : ${data.summaryDatabase}`} 
                className={classes.label}
                onClick={() => {
                    data.setSelectState(4);
                }} />
            <TreeItem 
                nodeId="3" 
                label={`Area : ${data.summaryArea}`} 
                className={classes.label} />
        </TreeView>
    );
}
