import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { DataContext } from './DataContext';
import './Button.css';

const ReportButton = (props) => {
    const data = useContext(DataContext);

    const onClick = (area, equipment, date) => {
        const url = `https://docs.google.com/forms/d/e/1FAIpQLSe6caiU64y9zl469vJrSrP6YA5w-YuydsbqbZdrl5XXJRQnSg/viewform?usp=pp_url&entry.63704419=${area}&entry.2140220637=${equipment}&entry.1997788104=${date}`;
        window.open(url, '_blank');
    }

    return (
        <Button
            onClick={() => onClick(props.area, props.equipment, data.stopDate.getFullYear() + '-' + ('0' + (data.stopDate.getMonth()+1)).slice(-2) + '-' + ('0' + data.stopDate.getDate()).slice(-2))}
            id={props.id}
            className='blue-button'
        >
            Report
        </Button>
    );
};

export default ReportButton;