import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Resizable } from 're-resizable';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay-ts';
import { DataContext } from '../components/DataContext';
import { DefaultSpectrumChart } from '../components/DefaultSpectrumChart';
import Loading from '../components/Loading';
import './Spectrum.css';

function Spectrum() {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [isPointDisabled, setIsPointDisabled] = useState(false);
    const [isDateDisabled, setIsDateDisabled] = useState(true);
    const [pointOptions, setPointOptions] = useState([{'label': 'M1V', 'value': 'M1V'}]);
    const [dateOptions, setDateOptions] = useState([]);
    const [chartOptions, setChartOptions] = useState(DefaultSpectrumChart);
    const [isActive, setActive] = useState(true);
    const data = useContext(DataContext);

    useEffect(() => {
        const getToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: 'scg-chem-test',
                    scope: 'read:test',
                });
                data.setToken(accessToken);
            } catch (e) {
                console.log(e.message);
            }
        };
        getToken();
    }, []);

    useEffect(() => {
        const getPoints = async (area_id, equipment_id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/spectrum/${area_id}/${equipment_id}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setPointOptions(response.data);
            } catch (e) {
                console.log('Error from spectrum');
            }
        };

        if (data.spectrumPointRef) {
            clearPointValue();
        }
        if (data.equipment !== '') {
            getPoints(data.area, data.equipment);
            setIsPointDisabled(false);
        } else {
            setIsPointDisabled(true);
        }
    }, [data.equipment]);

    useEffect(() => {
        const getDates = async (area_id, equipment_id, point_id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/spectrum/${area_id}/${equipment_id}/${point_id}`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setDateOptions(response.data);
            } catch (e) {
                console.log('Error from spectrum');
            }
        };

        if (data.datesRef) {
            clearDateValue();
        }
        if (data.spectrumPoint !== '') {
            getDates(data.area, data.equipment, data.spectrumPoint);
            setIsDateDisabled(false);
        } else {
            setIsDateDisabled(true);
        }
    }, [data.spectrumPoint]);

    useEffect(() => {
        const configChartOptions = async (date_list) => {
            setActive(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/spectrum/${data.area}/${data.equipment}/${data.spectrumPoint}`, {
                    'date': date_list
                }, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setChartOptions({
                    title: {
                        text: '',
                    },
                    xAxis: {
                        title: {
                            text: 'Frequency',
                        },
                        plotLines: response.data.x_freq
                    },
                    series: response.data.values,
                });
            } catch (e) {
                console.log('Error from spectrum');
            }
            setActive(false);
        }

        if (data.dates.length !== 0) {
            const date_list = data.dates.map(function(date){
                return date.value;
            });
            configChartOptions(date_list);
        } 
    }, [data.dates]);

    const clearPointValue = () => {
        data.pointRef.select.clearValue();
        data.spectrumPointRef.select.clearValue();
    };

    const clearDateValue = () => {
        data.datesRef.select.clearValue();
        setChartOptions(DefaultSpectrumChart);
    };

    const onSelectSpectrumPoint = (selectedItem) => {
        if (selectedItem !== null) {
            data.setSpectrumPoint(selectedItem.value);
        } else {
            data.setSpectrumPoint('');
        }
    };

    const onSelectDate = (selectedItem) => {
        if (selectedItem.length !== 0) {
            data.setDates(selectedItem);
        } else {
            data.setDates([]);
        }
    };

    return (
        isAuthenticated && (
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading spectrum detail...'>
                <div className='container'>
                    <br></br>
                    <strong className='header' id='spectrum'>Spectrum Analysis</strong>
                    <p className='description'>This graph indicates the spectrum analysis for selected measurement point and method analysis.</p>
                    <br></br>
                    <div className='spectrum-dropdown-container'>
                        <div className='spectrum-select-dropdown'>
                            <Select
                                ref={ref => {data.setSpectrumPointRef(ref);}}
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={isPointDisabled}
                                options={pointOptions}
                                placeholder="Select Point"
                                isMulti={false}
                                onChange={onSelectSpectrumPoint}
                            />
                        </div>
                        <div className='spectrum-multi-select-dropdown'>
                            <Select
                                ref={ref => {data.setDatesRef(ref);}}
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={isDateDisabled}
                                options={dateOptions}
                                placeholder="Select Date"
                                isMulti={true}
                                onChange={onSelectDate}
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className="spectrum-chart-container">
                        <Resizable className="box" maxWidth={1100} minWidth={1100} lockAspectRatio>
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </Resizable>
                    </div>
                </div>
            </LoadingOverlay>
        )
    );
};

export default withAuthenticationRequired(Spectrum, {
    onRedirecting: () => <Loading page='spectrum' />
});
