import React from 'react';
import image1 from '../assets/1.jpeg';
import image2 from '../assets/2.jpeg';
import image3 from '../assets/3.jpeg';
import image4 from '../assets/4.jpeg';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Home.css';

function Home() {
    return (
        <>
            <div className='content-container'>
                <div className='text-container'>
                    <h1 style={{'fontSize': '32px'}}>REPCO NEX Industrial Solutions</h1>
                    <p style={{'fontSize': '16px'}}>World-class Standard Industrial Solutions Provider</p>
                    <p style={{'fontSize': '16px'}}>REPCO NEX Industrial Solutions is a subsidiary of Chemicals business, SCG offering one stop services for any kinds of maintenance ranging from general maintenance to integrated solutions and digital manufacturing.</p>
                    <p style={{'fontSize': '16px'}}>With more than 30 years of experiences as in petrochemicals industry, we bring our experiences to develop unique products, services and solutions that will enable better production efficiency both performance and environmentally friendly. </p>
                    <p style={{'fontSize': '16px'}}>Solutions offering includes </p>
                    <p style={{'fontSize': '16px'}}>Industrial Services
                        <li>Mechanical Services</li>
                        <li>Cleaning Services</li>
                        <li>Inspection & NDT Services</li>
                        <li>Electrical & Instrument Valve Services</li>
                        <li>Turnaround Management Services</li>
                    </p>
                    <p style={{'fontSize': '16px'}}>Industrial Solutions
                        <li>Reliability Solutions</li>
                        <li>Digital Asset Solutions</li>
                        <li>Innovative Asset Solutions</li>
                    </p>
                    <p style={{'fontSize': '16px'}}>Professional Consulting & Training
                        <li>Process Safety Management</li>
                    </p>
                    <p style={{'fontSize': '16px'}}>Engineering & Construction
                        <li>Engineering Technology</li>
                        <li>Construction Services</li>
                    </p>
                    <p style={{'fontSize': '16px'}}>For  more information about the service, please contact repconex@scg.com or call at +66 3-891-1959, +66 95-492-4174</p>
                </div>
                <div className='image-container'>
                    <Carousel>
                        <div>
                            <img src={image1} alt='1' />
                        </div>
                        <div>
                            <img src={image2} alt='2' />
                        </div>
                        <div>
                            <img src={image3} alt='3' />
                        </div>
                        <div>
                            <img src={image4} alt='4' />
                        </div>
                    </Carousel>
                </div>
            </div>
            <div className='home-footer'>COPYRIGHT &copy;2021 SCG CHEMICALS CO., LTD. ALL RIGHTS RESERVED.</div>
        </>
    )
}

export default Home;
