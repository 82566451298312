export const DefaultBubbleChart = {
    chart: {
        type: 'bubble',
    },
    title: {
        text: '',
    },
    credits: {
        enabled: false,
    },
    legend: {
        enabled: false,
    },
    xAxis: {
        title: {
            text: ''
        },
        categories: [],
        min: null,
        max: null,
    },
    yAxis: {
        title: {
            text: ''
        },
        categories: [],
        min: null,
        max: null,
    },
    tooltip: {
        pointFormat: 'Severity: {point.z}'
    },
    series: [
        {
            name: '',
            data: [],
        }
    ],
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    },
};

export const DefaultBubbleSize = [
    {
        "x": -5,
        "y": 0,
        "z": 0
    }, {
        "x": -6,
        "y": 0,
        "z": 1
    }, {
        "x": -7,
        "y": 0,
        "z": 2
    }, {
        "x": -8,
        "y": 0,
        "z": 3
    }, {
        "x": -9,
        "y": 0,
        "z": 4
    }
];