import React, { useState } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import ImageTooltip from '../components/ImageTooltip';
import Loading from '../components/Loading';
import './ImageTest.css';
import './Equipment.css';

function ImageTest() {
    const { isAuthenticated } = useAuth0();
    const [imageUrl, setImageUrl] = useState('');
    const [imageShort, setImageShort] = useState('');
    const [imageTitle, setImageTitle] = useState('');
    const [leftIndex, setLeftIndex] = useState(0);
    const [topIndex, setTopIndex] = useState(0);

    return(
        isAuthenticated && (
        <div className='container'>
            <br></br>
            <div className='picture-container'>
                <ImageTooltip short={imageShort} title={imageTitle} left={leftIndex} top={topIndex} />
                <img className='image' src={imageUrl} alt="" />
            </div>
            <div className='image-form'>
                <input className='image-input' type="text" onChange={e => setImageUrl(e.target.value)} placeholder="Enter Image URL"></input>
                <input className='image-input' type="text" onChange={e => setImageShort(e.target.value)} placeholder="Enter Image Short Name"></input>
                <input className='image-input' type="text" onChange={e => setImageTitle(e.target.value)} placeholder="Enter Image Title"></input>
                <input className='image-input' type="text" onChange={e => setLeftIndex(parseInt(e.target.value))} placeholder="Enter Left Index"></input>
                <input className='image-input' type="text" onChange={e => setTopIndex(parseInt(e.target.value))} placeholder="Enter Top Index"></input>
            </div>
        </div>
        )
    );
};

export default withAuthenticationRequired(ImageTest, {
    onRedirecting: () => <Loading page='image_test' />,
});