import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from '../components/Loading';
import { FaBars } from 'react-icons/fa';
import Area from './Area';
import Equipment from './Equipment';
import Point from './Point';
import Anomaly from './Anomaly';
import Spectrum from './Spectrum';
import ReportCreation from './ReportCreation';
import TableOfContents from '../components/TableOfContents';
import CurrentMachineSelection from '../components/CurrentMachineSelection';
import EquipmentSearch from '../components/EquipmentSearch';
import TreeViewList from '../components/TreeViewList';
import { DataContext } from '../components/DataContext';
import './Report.css';

const Report = () => {
    const [isContentOpen, setIsContentOpen] = useState(false);
    const [treeViewList, setTreeViewList] = useState([]);
    const [selectState, setSelectState] = useState(0);
    const data = useContext(DataContext);

    useEffect(() => {
        if (data.token !== '') {
            getCompanyList();
        }
    }, [data.token]);

    useEffect(() => {
        if (data.dashboardSelectState === 4) {
            getCompanyList();
            data.setCompany('');
            data.setDatabase('');
            data.setArea('');
            data.setEquipment('');
        }
        if (data.dashboardSelectState === 5 && data.company !== '') {
            onSelectCompany(data.company);
            data.setDatabase('');
            data.setArea('');
            data.setEquipment('');
        }
        if (data.dashboardSelectState === 6 && data.database !== '') {
            onSelectDatabase(data.database);
            data.setArea('');
            data.setEquipment('');
        }
    }, [data.dashboardSelectState]);

    const getCompanyList = async () => {
        setSelectState(0);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/params-by-level`, {
                headers: {
                    Authorization: `Bearer ${data.token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setTreeViewList(response.data);
        } catch (e) {
            console.log('Error from params-by-level');
        }
    };

    const onSelectCompany = async (company) => {
        data.setCompany(company);
        data.setDatabase('');
        data.setArea('');
        data.setEquipment('');
        setSelectState(1);
        data.setDashboardSelectState(0);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/params-by-level/${company}`, {
                headers: {
                    Authorization: `Bearer ${data.token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setTreeViewList(response.data);
        } catch (e) {
            console.log('Error from params-by-level');
        }
    };

    const onSelectDatabase = async (database) => {
        data.setDatabase(database);
        data.setArea('');
        data.setEquipment('');
        setSelectState(2);
        data.setDashboardSelectState(0);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/params-by-level/${data.company}/${database}`, {
                headers: {
                    Authorization: `Bearer ${data.token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setTreeViewList(response.data);
        } catch (e) {
            console.log('Error from params-by-level');
        }
    };

    const onSelectArea = async (area) => {
        data.setArea(area);
        data.setEquipment('');
        setSelectState(3);
        data.setDashboardSelectState(0);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/spectrum/${area}`, {
                headers: {
                    Authorization: `Bearer ${data.token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setTreeViewList(response.data);
        } catch (e) {
            console.log('Error from spectrum');
        }
    };

    const onSelectEquipment = async (equipment) => {
        data.setEquipment(equipment);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/spectrum/${data.area}`, {
                headers: {
                    Authorization: `Bearer ${data.token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setTreeViewList(response.data);
        } catch (e) {
            console.log('Error from spectrum');
        }
    };

    const FormList = () => {
        if (selectState === 0) {
            return <TreeViewList list={treeViewList} onSelect={onSelectCompany} />
        }
        if (selectState === 1) {
            return <TreeViewList list={treeViewList} onSelect={onSelectDatabase} /> 
        }
        if (selectState === 2) {
            return <TreeViewList list={treeViewList} onSelect={onSelectArea} /> 
        }
        if (selectState === 3) {
            return <TreeViewList list={treeViewList} onSelect={onSelectEquipment} /> 
        }
    };

    return (
        <>
            <div className='report-page-container'>
                <div className={isContentOpen ? 'table-of-content' : 'table-of-content-closed'}>
                    <strong className='content-header'>Table of Contents</strong>
                    <br></br>
                    <TableOfContents />
                    <br></br>
                    <strong className='content-header'>Current Selection</strong>
                    <br></br>
                    <CurrentMachineSelection />
                    <br></br>
                    <EquipmentSearch />
                    <strong className='content-header'>Machine Selection</strong>
                    <br></br>
                    <FormList />
                </div>
                <div className={isContentOpen ? 'toggle-button-container' : 'toggle-button-container-closed'}>
                    <a onClick={() => {setIsContentOpen(isContentOpen => !isContentOpen)}}>
                        <FaBars color='white' fontSize='26px' />
                    </a>
                </div>
                <div className={isContentOpen ? 'report-container' : 'report-container-full'}>
                    <Area />
                    <Equipment />
                    <Point />
                    <Anomaly />
                    <Spectrum />
                    <ReportCreation />
                </div>
            </div>
            <div className='spectrum-footer'>COPYRIGHT &copy;2021 SCG CHEMICALS CO., LTD. ALL RIGHTS RESERVED.</div>
        </>
    );
}

export default withAuthenticationRequired(Report, {
    onRedirecting: () => <Loading page='report' />,
});