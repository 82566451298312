import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { DataContext } from '../components/DataContext';
import Loading from '../components/Loading';
import GetAPI from "../util/GetAPI";


const DevOnly = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [debug, setDebug] = useState({})
    const [profile, setProfile] = useState({})
    const data = useContext(DataContext)

    const toggleDebug = async () => {
        setDebug({...debug, debug_mode: await GetAPI('/toggle-debug', data.token)})
    }
    const resetConnection = async () =>  {
        setDebug({...debug, restart_count: await GetAPI('/restart', data.token)})
    }
    const getProfile = useCallback(async () => {
        if (data.token) {
            setDebug(await GetAPI('/debug', data.token))
            setProfile(await GetAPI('/profile', data.token))
        }
    }, [data.token])

    const getToken = useCallback(async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                audience: 'scg-chem-test',
            });
            data.setToken(accessToken);
            
        } catch (e) {
            console.log(e.message);
        }
    }, [getAccessTokenSilently, data])

    useEffect(() => {
        getToken()
        getProfile() 
    }, [getToken, getProfile]);

    return (
        <>
            <button onClick={() => {getToken(); getProfile();}}>refresh</button>
            <button onClick={() => toggleDebug()}>toggle debug</button> 
            <button onClick={() => resetConnection()}>restart connection</button> 
            <p>Debug mode: {debug.debug_mode? "True" : "False"}</p> 
            <p>Last backend connection time: {debug.last_connection_time}</p>
            <p>Backend restart count: {debug.restart_count}</p>
            <p>Email: {profile.email}</p> 
            <p>Customer name: {profile.customer_name}</p> 
            <br></br>
            <p>Token:</p>
            <p>{data.token}</p>
        </>
    )
}
export default withAuthenticationRequired(DevOnly, {
    onRedirecting: () => <Loading page='devonly' />,
});