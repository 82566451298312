import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import circle from '../assets/red_circle.png';

export default function ImageTooltip (props) {
    return (
        <div style={{
            position: 'absolute',
            left: props.left,
            top: props.top,
            'textAlign': 'center',}} >
            <Tooltip title={<div style={{ 'textAlign': 'center', }}>{props.title.split(';').map(function (address, index) {
                return <div key={index}>{ address }<br/></div>; 
            })}</div>} placement="top" arrow>
                <img style={{
                    width: '12px',
                    height: '12px',}} src={circle} alt='circle' />
            </Tooltip>
            <h2 style={{ color: 'red', }} >{props.short}</h2>
        </div>
    );
}
