export const DefaultDateChart = {
    title: {
        text: '',
    },
    tooltip: {
        valueDecimals: 3,
        shared: true,
    },
    credits: {
        enabled: false,
    },
    xAxis: {
        categories: []
    },
    yAxis: {
        title: {
            text: '',
        }
    },
    series: [
        {
            name: '',
            data: []
        }
    ],
    plotOptions: {
        series: {
            connectNulls: true
        }
    }
};