import React, { createContext, useState } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [token, setToken] = useState('');
    const [company, setCompany] = useState('');
    const [summaryCompany, setSummaryCompany] = useState('');
    const [database, setDatabase] = useState('');
    const [summaryDatabase, setSummaryDatabase] = useState('');
    const [area, setArea] = useState('');
    const [summaryArea, setSummaryArea] = useState('');
    const [equipment, setEquipment] = useState('');
    const [point, setPoint] = useState('');
    const [anomalyPoints, setAnomalyPoints] = useState([]);
    const [problem, setProblem] = useState('');
    const [date, setDate] = useState('');
    const [spectrumPoint, setSpectrumPoint] = useState('');
    const [dates, setDates] = useState([]);
    const [pointRef, setPointRef] = useState(null);
    const [anomalyPointsRef, setAnomalyPointsRef] = useState(null);
    const [problemRef, setProblemRef] = useState(null);
    const [dateRef, setDateRef] = useState(null);
    const [spectrumPointRef, setSpectrumPointRef] = useState(null);
    const [datesRef, setDatesRef] = useState(null);
    const [startDate, setStartDate] = useState(new Date((new Date()).setMonth((new Date()).getMonth() - 12)));
    const [stopDate, setStopDate] = useState(new Date());
    const [selectState, setSelectState] = useState(0);
    const [dashboardSelectState, setDashboardSelectState] = useState(0);
    const reportVersion = true;

    return (
        <DataContext.Provider
        value={{
            token,
            company,
            summaryCompany,
            database,
            summaryDatabase,
            area,
            summaryArea,
            equipment,
            point,
            anomalyPoints,
            problem,
            date,
            spectrumPoint,
            dates,
            pointRef,
            anomalyPointsRef,
            problemRef,
            dateRef,
            spectrumPointRef,
            datesRef,
            startDate,
            stopDate,
            reportVersion,
            selectState,
            dashboardSelectState,
            setToken,
            setCompany,
            setSummaryCompany,
            setDatabase,
            setSummaryDatabase,
            setArea,
            setSummaryArea,
            setEquipment,
            setPoint,
            setAnomalyPoints,
            setProblem,
            setDate,
            setSpectrumPoint,
            setDates,
            setPointRef,
            setAnomalyPointsRef,
            setProblemRef,
            setDateRef,
            setSpectrumPointRef,
            setDatesRef,
            setStartDate,
            setStopDate,
            setSelectState,
            setDashboardSelectState
        }}
        >
        {children}
        </DataContext.Provider>
    );
};