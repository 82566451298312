import React, { useState, useContext } from 'react';
import axios from 'axios';
import { DataContext } from './DataContext';
import { Button } from 'react-bootstrap';
import SearchResultList from './SearchResultList';
import './Search.css';

export default function AreaSearch() {
    const [selectedArea, setSelectedArea] = useState('');
    const [resultList, setResultList] = useState([]);
    const data = useContext(DataContext);

    const onClickSearch = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/search-area/${selectedArea}`, {
                headers: {
                    Authorization: `Bearer ${data.token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setResultList(response.data.map(({ company, database, area }) => (
                {
                    'label': `${company} - ${database} - ${area}`,
                    'company': company,
                    'database': database,
                    'area': area,
                    'equipment': ''
                }
            )));
        } catch (e) {
            console.log('Error from search-area');
        }
    };

    const onSelectArea = async (company, database, area, equipment) => {
        data.setSummaryCompany(company);
        data.setSummaryDatabase(database);
        data.setSummaryArea(area);
    };

    return (
        <>
        <div className='form'>
            <input type="text" className="input" onChange={e => (setSelectedArea(e.target.value))} value={selectedArea} placeholder="Enter Area"></input>
            <div>
            <Button
                id={1}
                className='blue-button'
                onClick={onClickSearch}
            >
                Search
            </Button>
            </div>
        </div>
        <br></br>
        <SearchResultList list={resultList} onSelect={onSelectArea} />
        </>
    );
}