import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';

const columns = [
  { id: 'point', label: 'Point', minWidth: 60 },
  { id: 'risk_function', label: 'Risk Function', minWidth: 150 },
  { id: 'problem', label: 'Problem', minWidth: 200 },
  { id: 'detail', label: 'Detail', minWidth: 100 },
  { id: 'date', label: 'Date', minWidth: 120, align: 'right' },
  { id: 'severity', label: 'Severity', minWidth: 80, align: 'right' },
  { id: 'value', label: 'Value', minWidth: 80, align: 'right', format: (value) => value.toFixed(4) },
  { id: 'recommendation', label: 'Recommendation', minWidth: 300 },
];

const useStyles = makeStyles({
    tableRow: {
      height: 80,
    },
    tableCell: {
      padding: "0px 16px"
    },
    container: {
      maxHeight: 400,
    },
});

const EquipmentPointAnomalyTable = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              {columns.map((column) => (
                <TableCell className={classes.tableCell}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.point} className={classes.tableRow}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default EquipmentPointAnomalyTable;
