import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Resizable } from 're-resizable';
import LoadingOverlay from 'react-loading-overlay-ts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ImageTooltip from '../components/ImageTooltip';
import DetailTable from '../components/DetailTable';
import { DefaultDateChart } from '../components/DefaultDateChart';
import { DefaultSpectrumChart } from '../components/DefaultSpectrumChart';
import Loading from '../components/Loading';
import './Detail.css';

const Detail = (props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState('');
    const [area, setArea] = useState('');
    const [equipment, setEquipment] = useState('');
    const [point, setPoint] = useState('');
    const [date, setDate] = useState('');
    const [equipmentDetail, setEquipmentDetail] = useState([]);
    const [imageTooltips, setImageTooltips] = useState([]);
    const [featureChartOptions, setFeatureChartOptions] = useState(DefaultDateChart);
    const [spectrumChartOptions, setSpectrumChartOptions] = useState(DefaultSpectrumChart);
    const [detailRows, setDetailRows] = useState([]);
    const [isActive, setActive] = useState(true);

    const startDate = new Date((new Date()).setMonth((new Date()).getMonth() - 12));
    const stopDate = new Date();

    useEffect(() => {
        const getToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: 'scg-chem-test',
                    scope: 'read:test',
                });
                setToken(accessToken);
            } catch (e) {
                console.log(e.message);
            }
        };
        getToken();
    }, []);

    useEffect(() => {
        const getParams = async (id) => {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/expert-form-last-measure-date/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setArea(response.data.area_id);
            setEquipment(response.data.equipment_id);
            setPoint(response.data.point_id);
            setDate(response.data.measurement_date);
            setDetailRows([
                {
                    'topic': 'Area',
                    'detail': response.data.area_id
                }, {
                    'topic': 'Equipment',
                    'detail': response.data.equipment_id
                }, {
                    'topic': 'Point',
                    'detail': response.data.point_id
                }, {
                    'topic': 'Acknowledged Date',
                    'detail': response.data.date
                }, {
                    'topic': 'Problem Type',
                    'detail': response.data.problem_type
                }, {
                    'topic': 'Problem Detail',
                    'detail': response.data.problem_detail
                }, {
                    'topic': 'Recommendation',
                    'detail': response.data.recommendation
                }, {
                    'topic': 'Analysis',
                    'detail': response.data.analysis
                }, {
                    'topic': 'Note',
                    'detail': response.data.note
                }, {
                    'topic': 'Analyzed and Reported by',
                    'detail': response.data.expert_name
                }
            ]);
        };

        if (token !== '') {
            getParams(props.match.params.id);
        }
    }, [token]);

    useEffect(() => {
        const getEquipmentDetail = async (area_id, equipment_id) => {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/equipment-detail/${area_id}/${equipment_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setEquipmentDetail(response.data);
        };

        const getEquipmentTooltip = async (area_id, equipment_id) => {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/tooltips/${area_id}/${equipment_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setImageTooltips(response.data.map((tooltip) =>
                <ImageTooltip short={tooltip.short} title={tooltip.title} left={tooltip.left} top={tooltip.top} key={tooltip.short} />
            ))
        };

        if (equipment !== ''){
            getEquipmentDetail(area, equipment);
            getEquipmentTooltip(area, equipment);
        } else {
            setImageTooltips([]);
            setEquipmentDetail([]);
        }
    }, [equipment]);

    useEffect(() => {
        const getFeaturesChart = async (area_id, equipment_id, point_id) => {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/feature/${area_id}/${equipment_id}/${point_id}`, {
                'feature_names': ['Overall'],
                'start_date': startDate.getDate() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getFullYear(),
                'end_date': stopDate.getDate() + "/" + (stopDate.getMonth() + 1) + "/" + stopDate.getFullYear()
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setFeatureChartOptions({
                xAxis: {
                    categories: response.data.dates
                },
                series: response.data.features,
                yAxis: {
                    plotLines: [
                        {
                            color: '#63bc44',
                            width: 3,
                            value: response.data.mean,
                            zIndex: 5,
                            dashStyle: 'dash',
                            label: {
                                text: 'Average',
                                align: 'right',
                                y: 20,
                                style: {
                                    color: '#63bc44',
                                    fontWeight: 'bold',
                                }
                            }
                        }, {
                            color: '#ec3424',
                            width: 3,
                            value: response.data.mean_3std,
                            zIndex: 5,
                            dashStyle: 'dash',
                            label: {
                                text: 'Danger (Anomaly)',
                                align: 'right',
                                y: 20,
                                style: {
                                    color: '#ec3424',
                                    fontWeight: 'bold'
                                }
                            }
                        }, 
                    ]
                }
            });
        }

        if (point !== '') {
            getFeaturesChart(area, equipment, point);
        }
    }, [point]);

    useEffect(() => {
        const getSpectrumChart = async (area, equipment, point, date) => {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/spectrum/${area}/${equipment}/${point}`, {
                'date': [date]
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setSpectrumChartOptions({
                xAxis: {
                    title: {
                        text: 'Frequency',
                    },
                    plotLines: response.data.x_freq
                },
                series: response.data.values,
            });
            setActive(false);
        }

        if (date !== '') {
            getSpectrumChart(area, equipment, point, date);
        } 
    }, [date]);

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading report detail...'>
                <div className='detail-page-container'>
                    <br></br>
                    <h1 className='header'>Report Detail</h1>
                    <div className='equipment-detail-container'>
                        <div className='picture-container'>
                            {imageTooltips}
                            <img className='picture' src={equipmentDetail[3]} alt="" />
                        </div>
                        <div className='equipment-title'>
                            <div className='equipment-title-text'>Machine Description:</div>
                            <div className='equipment-title-text'>Driver Unit:</div>
                            <div className='equipment-title-text'>Driven Unit:</div>
                            <div className='equipment-title-text'>Power Transmission:</div>
                            <div className='equipment-title-text'>Main Type:</div>
                            <div className='equipment-title-text'>Sub Type:</div>
                            <div className='equipment-title-text'>Power:</div>
                            <div className='equipment-title-text'>Speed Variation:</div>
                        </div>
                        <div className='equipment-value'>
                            <div className='equipment-value-text'>{equipmentDetail[2]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[7]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[8]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[9]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[10]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[11]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[14]}</div>
                            <div className='equipment-value-text'>{equipmentDetail[15]}</div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className='detail-table-container'>
                        <Resizable className="box" maxWidth={900} minWidth={900} lockAspectRatio>
                            <DetailTable rows={detailRows} />
                        </Resizable>
                    </div>
                    <br></br>
                    <h1 className='header'>Overall Feature</h1>
                    <div className='detail-chart-container'>
                        <Resizable className="box" maxWidth={1200} minWidth={1200} lockAspectRatio>
                            <HighchartsReact highcharts={Highcharts} options={featureChartOptions} />
                        </Resizable>
                    </div>
                    <h1 className='header'>Spectrum</h1>
                    <div className='detail-chart-container'>
                        <Resizable className="box" maxWidth={1200} minWidth={1200} lockAspectRatio>
                            <HighchartsReact highcharts={Highcharts} options={spectrumChartOptions} />
                        </Resizable>
                    </div>
                    <br></br>
                </div>
            </LoadingOverlay>
            <div className='footer'>COPYRIGHT &copy;2021 SCG CHEMICALS CO., LTD. ALL RIGHTS RESERVED.</div>
        </>
    );
};

export default withAuthenticationRequired(Detail, {
    onRedirecting: () => <Loading page='detail' />,
});