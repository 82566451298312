import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Resizable } from 're-resizable';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LoadingOverlay from 'react-loading-overlay-ts';
import Loading from '../components/Loading';
import { FaBars } from 'react-icons/fa';
import CurrentAreaSelection from '../components/CurrentAreaSelection';
import AreaSearch from '../components/AreaSearch';
import TreeViewList from '../components/TreeViewList';
import { defaultISOChartSummary } from '../components/DefaultISOChart';
import EngineerRecommendationTable from '../components/EngineerRecommendationTable';
import { DataContext } from '../components/DataContext';
import './Summary.css';

const Summary = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [isContentOpen, setIsContentOpen] = useState(false);
    const [startDate, setStartDate] = useState(new Date((new Date()).setMonth((new Date()).getMonth() - 12)));
    const [stopDate, setStopDate] = useState(new Date());
    const [formTableRows, setFormTableRows] = useState([]);
    const [machineNumber, setMachineNumber] = useState(0);
    const [measurementNummber, setMeasurementNumber] = useState(0);
    const [normalNumber, setNormalNumber] = useState(0);
    const [warningNumber, setWarningNumber] = useState(0);
    const [dangerNumber, setDangerNumber] = useState(0);
    const [undefinedNumber, setUndefinedNumber] = useState(0);
    const [ISOChartSummary, setISOChartSummary] = useState(defaultISOChartSummary);
    const [isLoading, setIsLoading] = useState(true);
    const [treeViewList, setTreeViewList] = useState([]);
    const [selectState, setSelectState] = useState(0);
    const data = useContext(DataContext);

    useEffect(() => {
        const getToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: 'scg-chem-test',
                    scope: 'read:test',
                });
                // console.log(accessToken);
                data.setToken(accessToken);
            } catch (e) {
                console.log(e.message);
            }
        };
        getToken();
    }, []);

    useEffect(() => {
        const getSummaryArea = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/profile-init-params`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                if (response.data.area_id !== '') {
                    data.setSummaryCompany(response.data.company_name);
                    data.setSummaryDatabase(response.data.customer_name);
                    data.setSummaryArea(response.data.area_id);
                }
            } catch (e) {
                console.log('Error from profile-init-params');
            }
        };

        if (data.token !== '') {
            getSummaryArea();
            getCompanyList();
        }
    }, [data.token]);

    useEffect(() => {
        const getSummaryAreaData = async (payload) => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/iso-level/count-latest`, payload, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                setMachineNumber(response.data.total_equipment);
                setMeasurementNumber(response.data.total_measurement);
                setNormalNumber(response.data.normal);
                setWarningNumber(response.data.warning);
                setDangerNumber(response.data.danger);
                setUndefinedNumber(response.data.undefined);
            } catch (e) {
                console.log('Error from iso-level/count-latest');
            }
        };

        const getISOChartSummary = async (payload) => {
            setIsLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/iso-level/count-latest`, payload, 
                    {
                        headers: {
                            Authorization: `Bearer ${data.token}`,
                            'Access-Control-Allow-Origin': '*'
                        },
                    }
                );
                setISOChartSummary({
                    series: [
                        {
                            name: 'Number of Measurement',
                            data: [{
                                name: 'Normal',
                                y: response.data.normal,
                                color: '#63bc44'
                            }, {
                                name: 'Warning',
                                y: response.data.warning,
                                color: '#fb9b05'
                            }, {
                                name: 'Danger',
                                y: response.data.danger,
                                color: '#ec3424',
                            }, {
                                name: 'Undefined',
                                y: response.data.undefined,
                                color: '#dedede',
                            }]
                        }
                    ]
                });
            } catch (e) {
                console.log('Error from iso-level/count');
            }
            setIsLoading(false);
        };

        const getFormTable = async (payload) => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/expert-form`, payload, 
                    {
                        headers: {
                            Authorization: `Bearer ${data.token}`,
                            'Access-Control-Allow-Origin': '*'
                        },
                    }
                );
                setFormTableRows(response.data);
            } catch (e) {
                console.log('Error from expert-form');
            }
        };

        if (data.summaryArea !== '') {
            const payload = {
                'start_date': startDate.getDate() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getFullYear(),
                'end_date': stopDate.getDate() + "/" + (stopDate.getMonth() + 1) + "/" + stopDate.getFullYear(),
                'area_list': [data.summaryArea],
            }
            getSummaryAreaData(payload);
            getISOChartSummary(payload);
            getFormTable(payload);
        }
    }, [data.summaryArea, startDate, stopDate]);

    useEffect(() => {
        if (data.selectState === 3) {
            getCompanyList();
            data.setSummaryCompany('');
            data.setSummaryDatabase('');
            data.setSummaryArea('');
        }
        if (data.selectState === 4 && data.summaryCompany !== '') {
            onSelectCompany(data.summaryCompany);
            data.setSummaryDatabase('');
            data.setSummaryArea('');
        }
    }, [data.selectState]);

    const getCompanyList = async () => {
        setSelectState(0);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/params-by-level`, {
                headers: {
                    Authorization: `Bearer ${data.token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setTreeViewList(response.data);
        } catch (e) {
            console.log('Error from params-by-level');
        }
    };

    const onSelectCompany = async (company) => {
        data.setSummaryCompany(company);
        data.setSummaryDatabase('');
        data.setSummaryArea('');
        setSelectState(1);
        data.setSelectState(0);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/params-by-level/${company}`, {
                headers: {
                    Authorization: `Bearer ${data.token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setTreeViewList(response.data);
        } catch (e) {
            console.log('Error from params-by-level');
        }
    };

    const onSelectDatabase = async (database) => {
        data.setSummaryDatabase(database);
        data.setSummaryArea('');
        setSelectState(2);
        data.setSelectState(0);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/params-by-level/${data.summaryCompany}/${database}`, {
                headers: {
                    Authorization: `Bearer ${data.token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setTreeViewList(response.data);
        } catch (e) {
            console.log('Error from params-by-level');
        }
    };

    const onSelectArea = async (area) => {
        data.setSummaryArea(area);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/params-by-level/${data.summaryCompany}/${data.summaryDatabase}`, {
                headers: {
                    Authorization: `Bearer ${data.token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setTreeViewList(response.data);
        } catch (e) {
            console.log('Error from params-by-level');
        }
    };

    const FormList = () => {
        if (selectState === 0) {
            return <TreeViewList list={treeViewList} onSelect={onSelectCompany} />
        }
        if (selectState === 1) {
            return <TreeViewList list={treeViewList} onSelect={onSelectDatabase} /> 
        }
        if (selectState === 2) {
            return <TreeViewList list={treeViewList} onSelect={onSelectArea} /> 
        }
    };

    return (
        isAuthenticated && (
            <>
                <div className='summary-page-container'>
                    <div className={isContentOpen ? 'area-selection' : 'area-selection-closed'}>
                        <strong className='content-header'>Current Selection</strong>
                        <br></br>
                        <CurrentAreaSelection />
                        <br></br>
                        <AreaSearch />
                        <strong className='content-header'>Area Selection</strong>
                        <br></br>
                        <FormList />
                    </div>
                    <div className={isContentOpen ? 'toggle-button-container' : 'toggle-button-container-closed'}>
                        <a onClick={() => {setIsContentOpen(isContentOpen => !isContentOpen)}}>
                            <FaBars color='white' fontSize='26px' />
                        </a>
                    </div>
                    <div className={isContentOpen ? 'summary-container' : 'summary-container-full'}>
                        <LoadingOverlay
                            active={isLoading}
                            spinner
                            text='Loading area summary...'>
                            <div className='container'>
                                <br></br>
                                <strong className='summary-header' id='summary'>Summary</strong>
                                <div className='summary-dropdown-container'>
                                    <div className='summary-datepicker-dropdown'>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="start-date"
                                                label="Start Date"
                                                value={startDate}
                                                onChange={setStartDate}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className='summary-datepicker-dropdown'>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="stop-date"
                                            label="End Date"
                                            value={stopDate}
                                            onChange={setStopDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <br></br>
                                <br></br>
                                <div className='dashboard-container'>
                                    <div className='number-container'>
                                        <div className='number-row'>
                                            <div className='number'>
                                                <p className='number-text'>TOTAL</p>
                                                <p className='number-number'>{machineNumber}</p>
                                                <p className='number-text'>MACHINE(S)</p>
                                            </div>
                                            <div className='number'>
                                                <p className='number-text'>NORMAL</p>
                                                <p className='number-number'>{normalNumber}</p>
                                                <p className='number-text'>MACHINE(S)</p>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className='number-row'>
                                            <div className='number'>
                                                <p className='number-text'>WARNING</p>
                                                <p className='number-number'>{warningNumber}</p>
                                                <p className='number-text'>MACHINE(S)</p>
                                            </div>
                                            <div className='number'>
                                                <p className='number-text'>DANGER</p>
                                                <p className='number-number'>{dangerNumber}</p>
                                                <p className='number-text'>MACHINE(S)</p>
                                            </div>
                                        </div>
                                        <br></br>
                                        <p className='remark-text'>* Remark : Machines without any metadata or measurement will be counted in Total.</p>
                                    </div>
                                    <Resizable className="box" maxWidth={500} minWidth={500} lockAspectRatio>
                                        <HighchartsReact highcharts={Highcharts} options={ISOChartSummary} />
                                    </Resizable>
                                </div>
                                <br></br>
                                <strong className='summary-header' id="summary_machine_abnormal_report">Machine Abnormal Report</strong>
                                <br></br>
                                <div className='summary-table-container'>
                                    <Resizable className="box" maxWidth={1100} minWidth={1100} lockAspectRatio>
                                        <EngineerRecommendationTable rows={formTableRows} />
                                    </Resizable>
                                </div>
                                <br></br>
                            </div>
                        </LoadingOverlay>
                    </div>
                </div>
                <div className='spectrum-footer'>COPYRIGHT &copy;2021 SCG CHEMICALS CO., LTD. ALL RIGHTS RESERVED.</div>
            </>
        )
    );
}

export default withAuthenticationRequired(Summary, {
    onRedirecting: () => <Loading page='summary' />,
});