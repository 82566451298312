import React, { useState, useContext } from 'react';
import axios from 'axios';
import { DataContext } from './DataContext';
import { Button } from 'react-bootstrap';
import SearchResultList from './SearchResultList';
import './Search.css';

export default function EquipmentSearch() {
    const [selectedEquipemnt, setSelectedEquipment] = useState('');
    const [resultList, setResultList] = useState([]);
    const data = useContext(DataContext);

    const onClickSearch = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/search-equipment/${selectedEquipemnt}`, {
                headers: {
                    Authorization: `Bearer ${data.token}`,
                    'Access-Control-Allow-Origin': '*'
                },
            });
            setResultList(response.data.map(({ company, database, area, equipment }) => (
                {
                    'label': `${company} - ${database} - ${area} - ${equipment}`,
                    'company': company,
                    'database': database,
                    'area': area,
                    'equipment': equipment
                }
            )));
        } catch (e) {
            console.log('Error from search-equipment');
        }
    };

    const onSelectEquipment = async (company, database, area, equipment) => {
        data.setCompany(company);
        data.setDatabase(database);
        data.setArea(area);
        data.setEquipment(equipment);
    };

    return (
        <>
        <div className='form'>
            <input type="text" className="input" onChange={e => (setSelectedEquipment(e.target.value))} value={selectedEquipemnt} placeholder="Enter Machine"></input>
            <div>
            <Button
                id={1}
                className='blue-button'
                onClick={onClickSearch}
            >
                Search
            </Button>
            </div>
        </div>
        <br></br>
        <SearchResultList list={resultList} onSelect={onSelectEquipment} />
        </>
    );
}