export const defaultISOChartByMonth = {
    chart: {
        type: 'column'
    },
    title: {
        text: '',
    },
    tooltip: {
        shared: true,
    },
    credits: {
        enabled: false,
    },
    xAxis: {
        categories: []
    },
    yAxis: {
        title: {
            text: 'Number of Machines',
        }
    },
    series: [ 
        {
            name: 'Danger',
            data: [],
            stack: 'ISO',
            color: '#ec3424'
        },
        {
            name: 'Warning',
            data: [],
            stack: 'ISO',
            color: '#fb9b05'
        }, 
        {
            name: 'Normal',
            data: [],
            stack: 'ISO',
            color: '#63bc44'
        }
    ],
    plotOptions: {
        column: {
            stacking: 'normal'
        }
    }
};

export const defaultISOChartTotal = {
    chart: {
        type: 'bar'
    },
    title: {
        text: '',
    },
    tooltip: {
        shared: true,
    },
    credits: {
        enabled: false,
    },
    xAxis: {
        title: {
            text: 'Status',
        },
        categories: ['Danger', 'Warning', 'Normal', 'Undefined']
    },
    yAxis: {
        title: {
            text: 'Number of Machines',
        }
    },
    series: [ 
        {
            name: 'Number of Machines',
            data: [],
            showInLegend: false,
        }
    ]
};

export const defaultISOChartSummary = {
    chart: {
        type: 'pie',
        margin: [0, 0, 0, 0],
    },
    title: {
        text: 'Summary of Machine Health Monitoring',
    },
    credits: {
        enabled: false,
    },
    series: [],
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            },
            size: 250
        }
    },
};