import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Resizable } from 're-resizable';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LoadingOverlay from 'react-loading-overlay-ts';
import { DataContext } from '../components/DataContext';
import { defaultISOChartByMonth, defaultISOChartTotal } from '../components/DefaultISOChart';
import EngineerRecommendationTable from '../components/EngineerRecommendationTable';
import Loading from '../components/Loading';
import './Area.css';

function Area() {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [ISOChartByMonth, setISOChartByMonth] = useState(defaultISOChartByMonth);
    const [ISOChartTotal, setISOChartTotal] = useState(defaultISOChartTotal);
    const [formTableRows, setFormTableRows] = useState([]);
    const [isActive, setActive] = useState(true);
    const [ISOMonthLoading, setISOMonthLoading] = useState(true);
    const [ISOTotalLoading, setISOTotalLoading] = useState(true);
    const data = useContext(DataContext);

    useEffect(() => {
        const getToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: 'scg-chem-test',
                    scope: 'read:test',
                });
                // console.log(accessToken);
                data.setToken(accessToken);
            } catch (e) {
                console.log(e.message);
            }
        };
        getToken();
    }, []);

    useEffect(() => {
        const getDefaultParams = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/profile-init-params`, {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                });
                if (response.data.area_id !== '') {
                    data.setCompany(response.data.company_name);
                    data.setDatabase(response.data.customer_name);
                    data.setArea(response.data.area_id);
                    data.setEquipment(response.data.equipment_id);
                    data.setPoint(response.data.point_id);
                    data.setProblem(response.data.problem);
                    data.setAnomalyPoints([response.data.point_id]);
                    data.setDate(response.data.date);
                    data.setSpectrumPoint([response.data.point_id]);
                    data.setDates([{label: response.data.date, value: response.data.date}]);
                    if (data.pointRef) {
                        data.pointRef.setState({ value: [{label: response.data.point_id, value: response.data.point_id}] });
                    }
                    if (data.anomalyPointsRef) {
                        data.anomalyPointsRef.setState({ value: [{label: response.data.point_id, value: response.data.point_id}] });
                    }
                    if (data.problemRef) {
                        data.problemRef.setState({ value: [{label: response.data.problem, value: response.data.problem}] });
                    }
                    if (data.dateRef) {
                        data.dateRef.setState({ value: [{label: response.data.date, value: response.data.date}] });
                    }
                    if (data.spectrumPointRef) {
                        data.spectrumPointRef.setState({ value: [{label: response.data.point_id, value: response.data.point_id}] });
                    }
                    if (data.datesRef) {
                        data.datesRef.setState({ value: [{label: response.data.date, value: response.data.date}] });
                    }
                }
            } catch (e) {
                console.log('Error from profile-init-params');
            }
        };

        if (data.token !== '') {
            getDefaultParams();
        }
    }, [data.token]);

    useEffect(() => {
        const getISOChartByMonth = async (payload) => {
            setISOMonthLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/iso-level/count-month`, payload, 
                    {
                        headers: {
                            Authorization: `Bearer ${data.token}`,
                            'Access-Control-Allow-Origin': '*'
                        },
                    }
                );
                setISOChartByMonth({
                    xAxis: {
                        categories: response.data.months
                    },
                    series: [ 
                        {
                            name: 'Danger',
                            data: response.data.danger,
                        },
                        {
                            name: 'Warning',
                            data: response.data.warning,
                        }, 
                        {
                            name: 'Normal',
                            data: response.data.normal,
                        }
                    ],
                });
            } catch (e) {
                console.log('Error from iso-level/count-month');
            }
            setISOMonthLoading(false);
        };

        const getISOChartTotal = async (payload) => {
            setISOTotalLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/iso-level/count-latest`, payload, 
                    {
                        headers: {
                            Authorization: `Bearer ${data.token}`,
                            'Access-Control-Allow-Origin': '*'
                        },
                    }
                );
                setISOChartTotal({
                    xAxis: {
                        categories: response.data.months
                    },
                    series: [ 
                        {
                            name: 'Number of Machines',
                            data: [
                                {y: response.data.danger, color: '#ec3424'},
                                {y: response.data.warning, color: '#fb9b05'},
                                {y: response.data.normal, color: '#63bc44'},
                                {y: response.data.undefined, color: '#dedede'},
                            ],
                            showInLegend: false,
                        }
                    ],
                });
            } catch (e) {
                console.log('Error from iso-level/count-latest');
            }
            setISOTotalLoading(false);
        };

        const getFormTable = async (payload) => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/expert-form`, payload, 
                    {
                        headers: {
                            Authorization: `Bearer ${data.token}`,
                            'Access-Control-Allow-Origin': '*'
                        },
                    }
                );
                setFormTableRows(response.data);
            } catch (e) {
                console.log('Error from expert-form');
            }
        };

        if (data.area !== '') {
            const payload = {
                'start_date': data.startDate.getDate() + "/" + (data.startDate.getMonth() + 1) + "/" + data.startDate.getFullYear(),
                'end_date': data.stopDate.getDate() + "/" + (data.stopDate.getMonth() + 1) + "/" + data.stopDate.getFullYear(),
                'area_list': [data.area],
            }
            getISOChartByMonth(payload);
            getISOChartTotal(payload);
            getFormTable(payload);
        }
    }, [data.area, data.startDate, data.stopDate]);

    useEffect(() => {
        if (ISOTotalLoading === false || ISOTotalLoading === false) {
            setActive(false);
        } else {
            setActive(true);
        }
    }, [ISOMonthLoading, ISOTotalLoading]);
    
    return (
        isAuthenticated && (
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading area detail...'>
                <div className='container'>
                    <br></br>
                    <strong className='header' id='area'>Area</strong>
                    <p className='description'>Overall machine health status for selected area</p>
                    <div className='area-dropdown-container'>
                        <div className='area-datepicker-dropdown'>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="start-date"
                                    label="Start Date"
                                    value={data.startDate}
                                    onChange={data.setStartDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className='area-datepicker-dropdown'>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="stop-date"
                                label="End Date"
                                value={data.stopDate}
                                onChange={data.setStopDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <br></br>
                    <div className='area-chart-container'>
                        <Resizable className="box" maxWidth={600} minWidth={600} lockAspectRatio>
                            <HighchartsReact highcharts={Highcharts} options={ISOChartByMonth} />
                        </Resizable>
                        <Resizable className="box" maxWidth={525} minWidth={525} lockAspectRatio>
                            <HighchartsReact highcharts={Highcharts} options={ISOChartTotal} />
                        </Resizable>
                    </div>
                    <br></br>
                    <strong className='header' id="machine_abnormal_report">Machine Abnormal Report</strong>
                    <p className='description'>Information and recommendation for machine abnormal status</p>
                    <br></br>
                    <div className='area-table-container'>
                        <Resizable className="box" maxWidth={1100} minWidth={1100} lockAspectRatio>
                            <EngineerRecommendationTable rows={formTableRows} />
                        </Resizable>
                    </div>
                </div>
            </LoadingOverlay>
        )
    )
};

export default withAuthenticationRequired(Area, {
    onRedirecting: () => <Loading page='area' />,
});
