import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Resizable } from 're-resizable';
import LoadingOverlay from 'react-loading-overlay-ts';
import { DataContext } from '../components/DataContext';
import ISOEquipmentTable from '../components/ISOEquipmentTable';
import Loading from '../components/Loading';
import './ReportCreation.css';

function ReportCreation() {
    const { isAuthenticated } = useAuth0();
    const [ISOTableColumns, setISOTableColumns] = useState([]);
    const [ISOTableRows, setISOTableRows] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const data = useContext(DataContext);

    useEffect(() => {
        const getISOTable = async (payload) => {
            setIsTableLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/iso-level/month`, payload, 
                {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );
            setISOTableColumns(response.data.months);
            setISOTableRows(response.data.data);
            setIsTableLoading(false);
        };

        if (data.area !== '') {
            const payload = {
                'start_date': data.startDate.getDate() + "/" + (data.startDate.getMonth() + 1) + "/" + data.startDate.getFullYear(),
                'end_date': data.stopDate.getDate() + "/" + (data.stopDate.getMonth() + 1) + "/" + data.stopDate.getFullYear(),
                'area_list': [data.area],
            }
            getISOTable(payload);
        } else {
            setISOTableColumns([]);
            setISOTableRows([]);
        }
    }, [data.area, data.startDate, data.stopDate]);
    
    return (
        isAuthenticated && (
            <LoadingOverlay
                active={isTableLoading}
                spinner
                text='Loading report creation...'>
                <div className='container'>
                    <strong className='header' id='report_creation'>Report Creation</strong>
                    <p className='description'>The proposal of this table is for summary machine health for each machine tag and to create machine abnormal report at the report button.</p>
                    <br></br>
                    <div className='report-creation-table-container'>
                        <Resizable className="box" maxWidth={1100} minWidth={1100} lockAspectRatio>
                            <ISOEquipmentTable columns={ISOTableColumns} rows={ISOTableRows} />
                        </Resizable>
                    </div>
                    <br></br>
                    <br></br>
                </div>
            </LoadingOverlay>
        )
    )
};

export default withAuthenticationRequired(ReportCreation, {
    onRedirecting: () => <Loading page='report creation' />,
});
