import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Auth0ProviderWithHistory from './Auth0Provider.js';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import Detail from './pages/Detail';
import Report from './pages/Report';
import ImageTest from './pages/ImageTest';
import Summary from './pages/Summary';
import DevOnly from './pages/DevOnly';

const App = () => {
  return (
    <>
      <Router>
        <Auth0ProviderWithHistory>
          <Sidebar /> 
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/report' exact component={Report} />
            <Route path='/home' exact component={Summary} />
            <Route path='/image_test' exact component={ImageTest} />
            <Route path='/detail/:id' exact component={Detail} />
          </Switch>
        </Auth0ProviderWithHistory>
      </Router>
    </>
  );
};

export default App;
