import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, List, ListItem } from '@material-ui/core';

const Splitter = (value) => {
  const chapters = value.value.toString().split(";");
  return (
    <List>
      {chapters.map((chapter, i) => (
        <ListItem key={i} disableGutters>{chapter}</ListItem>
      ))}
    </List>
  );
}

const columns = [
  { id: 'component', label: 'Component', minWidth: 200 },
  { id: 'point', label: 'Point', minWidth: 60 },
  { id: 'group', label: 'Group', minWidth: 80, align: 'right' },
  { id: 'rpm', label: 'RPM', minWidth: 100, align: 'right', format: (value) => value.toLocaleString('en-US') },
  { id: 'special_feature', label: 'Special Feature List', minWidth: 225 },
  { id: 'risk_function', label: 'Risk Function List', minWidth: 200 },
];

const useStyles = makeStyles({
    tableRow: {
      height: 40,
    },
    tableCell: {
      padding: "0px 16px"
    },
    container: {
      maxHeight: 500,
    },
});

const EquipmentPointDetailTable = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              {columns.map((column) => (
                <TableCell className={classes.tableCell}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.point} className={classes.tableRow}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id === 'special_feature' || column.id === 'risk_function') {
                      value = <Splitter value={value} />;
                    }
                    return (
                      <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default EquipmentPointDetailTable;
