import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import StopIcon from '@material-ui/icons/Stop';
import TreeItem from '@material-ui/lab/TreeItem';

const useStyles = makeStyles({
    root: {
        flex: 1,
        maxWidth: 400,
    },
    label: {
        paddingTop: 2.5,
        paddingBottom: 2.5,
    },
});

export default function TableOfContents() {
    const classes = useStyles();

    return (
        <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultEndIcon={<StopIcon fontSize='small' />}
        >
            <TreeItem nodeId="1" label="Area" className={classes.label} onClick={() => {
                const anchor = document.querySelector('#area')
                anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}>
                <TreeItem nodeId="2" label="Machine abnormal report" className={classes.label} onClick={() => {
                    const anchor = document.querySelector('#machine_abnormal_report')
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }} />
            </TreeItem>
            <TreeItem nodeId="3" label="Equipment" className={classes.label} onClick={() => {
                const anchor = document.querySelector('#equipment')
                anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}>
                <TreeItem nodeId="4" label="Machine fault analysis" className={classes.label} onClick={() => {
                    const anchor = document.querySelector('#machine_fault_analysis')
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }} />
                <TreeItem nodeId="5" label="Overall selected machine health condition graph" className={classes.label} onClick={() => {
                    const anchor = document.querySelector('#machine_health_condition')
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }} />
                <TreeItem nodeId="6" label="Summary machine status for overall data" className={classes.label} onClick={() => {
                    const anchor = document.querySelector('#machine_status')
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }} />
                <TreeItem nodeId="7" label="Machine status: Overall band alarm" className={classes.label} onClick={() => {
                    const anchor = document.querySelector('#band_alarm')
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }} />
                <TreeItem nodeId="8" label="Band alarm analysis trend" className={classes.label} onClick={() => {
                    const anchor = document.querySelector('#band_alarm_trend')
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }} />
            </TreeItem>
            <TreeItem nodeId="9" label="Anomaly fault detection" className={classes.label} onClick={() => {
                const anchor = document.querySelector('#anomaly')
                anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }} />
            <TreeItem nodeId="10" label="Spectrum analysis" className={classes.label} onClick={() => {
                const anchor = document.querySelector('#spectrum')
                anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }} />
            <TreeItem nodeId="11" label="Report creation" className={classes.label} onClick={() => {
                const anchor = document.querySelector('#report_creation')
                anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }} />
        </TreeView>
    );
}
