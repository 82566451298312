import axios from 'axios';

const GetAPI = async(endpoint, token) => {    
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/${endpoint}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*'
            },
        });
        return response.data
    } catch (e) {
        console.log(e);
    }
}
export default GetAPI