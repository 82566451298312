import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import StopIcon from '@material-ui/icons/Stop';
import TreeItem from '@material-ui/lab/TreeItem';

const useStyles = makeStyles({
    root: {
        height: 375,
        flexGrow: 1,
        maxWidth: 400,
    },
    label: {
        paddingTop: 2.5,
        paddingBottom: 2.5,
    },
});

export default function TreeViewList(props) {
    const classes = useStyles();

    function FormList() {
        // console.log(props.list);
        return props.list.map(({ label, value }) => (
            <TreeItem 
                key={label} 
                nodeId={label} 
                label={label} 
                className={classes.label}
                onClick={() => {
                    props.onSelect(label);
                }} />
        ));
    }

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultEndIcon={<StopIcon fontSize='small' />}
            >
            <FormList />
            <br></br>
            <br></br>
            <br></br>
        </TreeView>
    );
}